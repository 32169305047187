body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", "微軟正黑體", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-container-thumbs {
  .swiper-slide {
    border: 2px solid transparent;
    transition: border-color .12s ease;
  }
  .swiper-slide-thumb-active {
    border: 2px solid #fff;
  }
}
